import Image from "next/image";
const OurPartners = () => {
  const partnerGroup = [{
    id: 1,
    link: "https://eaglegrit.com/",
    logo: "eagle-grit"
  }, {
    id: 2,
    link: "https://flowvisionco.com/",
    logo: "flowvision"
  }, {
    id: 3,
    link: "https://minimotomafia.com/",
    logo: "mini-moto-mafia"
  }, {
    id: 4,
    link: "/about-sponsoring",
    logo: "your-logo-here"
  }];
  return <>
      <div className="dark:bg-jacarta-900 ">
        <div className="container">
          <div className="grid grid-cols-2 py-8 sm:grid-cols-5">
            {partnerGroup.map(item => <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.id}>
                <Image width={173} height={103} className="object-contain" src={`/images/partners/${item.logo}.png`} alt="partner 1" />
              </a>)}
          </div>
        </div>
      </div>
    </>;
};
export default OurPartners;