
const banners = [
	{
		id: 1,
		imageUrl: '/images/banner-ads/resume-one.jpg',
        alt: 'Create your resume today',
        url: '/resume-builder?source=banner-ad'
	},
    {
		id: 2,
		imageUrl: '/images/banner-ads/national-track-map.jpg',
        alt: 'National Track Map',
        url: '/tracks?source=banner-ad'
	},
    {
		id: 2,
		imageUrl: '/images/banner-ads/loretta-lynn-tracker.jpg',
        alt: 'Loretta Lynn Tracker',
        url: '/loretta-lynn-tracker?source=banner-ad'
	}
];

export { banners };
