/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";
import HeadLine from "../headLine";
import Image from "next/image";
const UpcomingRaces = ({
  races
}) => {
  return <div data-sentry-component="UpcomingRaces" data-sentry-source-file="upcoming-races.jsx">
            <section className="dark:bg-jacarta-800 relative py-24">
                <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden" data-sentry-element="picture" data-sentry-source-file="upcoming-races.jsx">
                    <Image width={1519} height={773} priority src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full object-cover" data-sentry-element="Image" data-sentry-source-file="upcoming-races.jsx" />
                </picture>
                <div className="container">
                    <div className="font-display text-jacarta-700 mb-12 text-center text-lg sm:text-3xl dark:text-white flex justify-center items-center gap-x-3">
                        <HeadLine text="Upcoming Races" image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png" classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="upcoming-races.jsx" />
                    </div>

                    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-2">
                        {races?.map(({
            id,
            title,
            track,
            startDate,
            onlineSignupUrl,
            imageUrl
          }) => {
            return <div className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent" key={id}>
                                    <figure className="mr-4 shrink-0">
                                        <Link href={onlineSignupUrl} className="relative block">
                                            <img src={imageUrl} alt={title} className="rounded-2lg" height={48} width={48} />
                                        </Link>
                                    </figure>
                                    <div className="">
                                        <Link href={onlineSignupUrl} className="block">
                                            <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                                                {title}
                                            </span>
                                        </Link>
                                        <span className="dark:text-jacarta-300 text-sm">
                                            {track} on {startDate}
                                        </span>
                                        <div className="mt-4">
                                            {onlineSignupUrl && <Link href={onlineSignupUrl} className="text-sm bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-2 px-4 text-center font-semibold text-white transition-all">
                                                    Signup Online
                                                </Link>}
                                        </div>
                                    </div>
                                </div>;
          })}
                    </div>
                    <div className="mt-10 text-center">
                        <Link href="/apps/race-calendar" className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all" data-sentry-element="Link" data-sentry-source-file="upcoming-races.jsx">
                            View full Calendar
                        </Link>
                    </div>
                </div>
            </section>
        </div>;
};
export default UpcomingRaces;