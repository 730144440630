'use client';

import React, { useState, useEffect } from "react";
import HeadLine from "./headLine";
import Tippy from "@tippyjs/react";
import Link from "next/link";
import "tippy.js/themes/light.css";
import Image from "next/image";
import Likes from "./likes";
import { useUser } from "@auth0/nextjs-auth0/client";
const HomePageNewsFeed = () => {
  const {
    user,
    isLoading,
    error
  } = useUser();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [following, setFollowing] = useState(0);
  const [isLoadingFeed, setIsLoadingFeed] = useState(false);
  const [loadMoreBtn, setLoadMoreBtn] = useState(true);

  // useEffect(() => {
  //   const loadData = async () => {
  //     setIsLoadingFeed(true);
  //     const result = await fetch(`/api/feed/riders?page=${page}`);
  //     setIsLoadingFeed(false);

  //     if (result.status >= 300) {
  //       return;
  //     }

  //     const resultData = await result.json();

  //     setData([...data, ...resultData.feed]);
  //     setFollowing(resultData.following);
  //     setLoadMoreBtn(resultData.hasMore);
  //   }

  //   loadData();
  // }, [page])

  // todo: removing for performance

  const handleloadMore = () => {
    setPage(state => state + 1);
  };
  if (!user || isLoading) {
    return <></>;
  }
  return <div data-sentry-component="HomePageNewsFeed" data-sentry-source-file="home-page-news-feed.jsx">
      <section className="pb-6">
        <div className="container">
          <HeadLine text="Followed Riders Feed" classes="font-display text-jacarta-700 text-center text-3xl dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="home-page-news-feed.jsx" />
          {following === 0 && !isLoadingFeed ? <p className="text-center">You aren't currently following any riders. Follow a few riders and their results will appear in this feed.</p> : <p className="text-center">You are currently following {following} rider(s).</p>}
          <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4 mt-6">
            {data.map(item => {
            const {
              id,
              riderName,
              RaceEventClass,
              finishingNumber
            } = item;
            return <article key={id}>
                  <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                    <figure className="relative">
                      <Link href={`/rider/${riderName}`} prefetch={false}>
                        <div style={{
                      backgroundImage: `url('/api/rider-photo/${riderName}'`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: 240
                    }}>
                        </div>
                      </Link>
                    </figure>
                    <div className="mt-7 flex items-center justify-between">
                      <Link href={`/rider/${riderName}`} prefetch={false}>
                        <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                          {riderName}
                        </span>
                      </Link>
                    </div>
                    <div className="mt-2 text-sm">
                      <Link href={`/race-result/${RaceEventClass.id}`} prefetch={false}>
                        <span className="dark:text-jacarta-300">
                          {RaceEventClass.className} | P:{finishingNumber}
                        </span>
                      </Link>
                      <Link href={`/races/${RaceEventClass.RaceEvent.id}`} prefetch={false}>
                        <div className="dark:text-jacarta-100 text-jacarta-700 mt-3">
                          <strong>{RaceEventClass.RaceEvent.name}</strong>
                        </div>
                      </Link>
                    </div>

                    <div className="mt-8 flex items-center justify-between">
                      <Link href={`/rider/${riderName}`} className="text-accent font-display text-sm font-semibold" prefetch={false}>
                        View Rider
                      </Link>
                    </div>
                  </div>
                </article>;
          })}
          </div>

          {isLoadingFeed && <p>Loading data...</p>}

          {!!loadMoreBtn && !isLoadingFeed && !!following && <div className="mt-10 text-center">
              <button onClick={handleloadMore} className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                Load More
              </button>
            </div>}

        </div>
      </section>
    </div>;
};
export default HomePageNewsFeed;