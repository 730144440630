import React, { useEffect, useState } from "react";
import { banners } from '../data/banners';
import Link from 'next/link';
const BannerAd = () => {
  const [banner, setBanner] = useState(null);
  useEffect(() => {
    const random = Math.floor(Math.random() * banners.length);
    setBanner(banners[random]);
  }, []);
  if (!banner) {
    return <></>;
  }
  return <Link href={banner.url} data-sentry-element="Link" data-sentry-component="BannerAd" data-sentry-source-file="banner-ad.jsx">
            <img src={banner.imageUrl} alt={banner.alt} style={{
      marginLeft: 'auto',
      marginRight: 'auto'
    }} />
        </Link>;
};
export default BannerAd;