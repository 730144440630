import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "./component";
import "tippy.js/dist/tippy.css";
import FeaturedTrainingFacilityCarousel from "./carousel/FeaturedTrainingFacilityCarousel";
import Image from "next/image";
import Link from "next/link";
const FeaturedTrainingFacilities = ({
  bgWhite,
  featuredFacilities
}) => {
  return <section className={"pt-10 pb-24"} data-sentry-component="FeaturedTrainingFacilities" data-sentry-source-file="FeaturedTrainingFacilities.jsx">
      {bgWhite && <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image width={1519} height={773} priority src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full object-cover" />
        </picture>}
      <div className="container">
        <HeadLine text="Featured Training Facilities" image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png" classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="FeaturedTrainingFacilities.jsx" />

        <div className="relative">
          <FeaturedTrainingFacilityCarousel featuredFacilities={featuredFacilities} data-sentry-element="FeaturedTrainingFacilityCarousel" data-sentry-source-file="FeaturedTrainingFacilities.jsx" />
        </div>

        <div className="mt-10 text-center">
          <Link href="/apps/training-facilities" className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all" data-sentry-element="Link" data-sentry-source-file="FeaturedTrainingFacilities.jsx">
            View all facilities
          </Link>
        </div>
      </div>
    </section>;
};
export default FeaturedTrainingFacilities;