import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "./component";
import "tippy.js/dist/tippy.css";
import FeaturedTrainersCarousel from "./carousel/FeaturedTrainersCarousel";
import Image from "next/image";
import Link from "next/link";
const FeaturedTrainers = ({
  classes = "pt-10 pb-24",
  bgWhite
}) => {
  return <section className={classes} data-sentry-component="FeaturedTrainers" data-sentry-source-file="FeaturedTrainers.jsx">
      {bgWhite && <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image width={1519} height={773} priority src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full object-cover" />
        </picture>}
      <div className="container">
        <HeadLine text="Featured Trainers" image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png" classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white" data-sentry-element="HeadLine" data-sentry-source-file="FeaturedTrainers.jsx" />

        <div className="relative">
          <FeaturedTrainersCarousel data-sentry-element="FeaturedTrainersCarousel" data-sentry-source-file="FeaturedTrainers.jsx" />
        </div>

        <div className="mt-10 text-center">
          <Link href="/apps/trainers" prefetch={false} className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all" data-sentry-element="Link" data-sentry-source-file="FeaturedTrainers.jsx">
            View all trainers
          </Link>
        </div>
      </div>
      {/* <!-- end hot bids --> */}
    </section>;
};
export default FeaturedTrainers;