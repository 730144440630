export const our_features = [
	{
		id: 'TRAINING_FACILITIES',
		img: '/images/hero/our-features/training-facilities.jpg',
		title: 'Find a training facility near you',
		authorName: '',
		link: '/apps/training-facilities'
	},
	{
		id: 'WEEKLY_WINNERS',
		img: '/images/hero/our-features/weekly-winners.jpg',
		title: 'Overall moto winners of the week',
		authorName: '',
		link: '/riders/weekly-winners'
	},
	{
		id: 'RACE_CALENDAR',
		img: '/images/hero/our-features/race-calendar.jpg',
		title: 'All races in the US in a single place',
		authorName: '',
		link: '/apps/race-calendar'
	},
	{
		id: 'LORETTA_TRACKER',
		img: '/images/hero/our-features/loretta-tracker.jpg',
		title: 'Amateur National Qualification Tracker',
		authorName: '',
		link: '/loretta-lynn-tracker'
	},
	{
		id: 'PROFILES',
		img: '/images/hero/our-features/rider-profiles.jpg',
		title: 'Rider Profile and Resume',
		authorName: 'Update your rider profile and generate your resume',
		link: '/rider/claim'
	},
	{
		id: 'PRODUCTS',
		img: '/images/hero/our-features/product-gallery.jpg',
		title: 'Companies that provide the essentials of the sport.',
		authorName: '',
		link: '/products'
	},
	{
		id: 'TRAINERS',
		img: '/images/hero/our-features/trainers.jpg',
		title: 'Find coaches in your area',
		authorName: '',
		link: '/apps/trainers'
	},
	{
		id: 'RACE_RESULTS',
		img: '/images/hero/our-features/races.jpg',
		title: 'Race Results updated daily',
		authorName: '',
		link: '/races'
	},
	{
		id: 'TRACKS',
		img: '/images/hero/our-features/tracks.jpg',
		title: 'US National Track Map',
		authorName: '',
		link: '/tracks'
	},
];
