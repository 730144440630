// import '/images/item_1.jpg' from '/images/item_1.jpg';

const featuredTrainers = [
	{
		id: 1,
		image: '/images/trainers/gregg-albertson.jpeg',
		name: 'Gregg Albertson',
		description: 'Game Moto',
		url: 'https://www.instagram.com/game_moto',
        location: 'OK'
	},
	{
		id: 2,
		image: '/images/trainers/mike-bottolfson.png',
		name: 'Mike Bottoflson',
		description: 'Motivated MX Training',
		url: 'https://www.instagram.com/motivatedmxtraining',
        location: 'TX'
	},
    {
		id: 3,
		image: '/images/trainers/daniel-blair.png',
		name: 'Daniel Blair',
		description: 'NxtLvl101',
		url: 'https://www.instagram.com/danielblair125',
        location: 'SC'
	},
    {
		id: 0,
		image: '/images/riders/no-pic.jpg',
		name: 'Your name here..',
		description: 'Click here to get featured',
		url: '/apps/trainers/become-featured',
        location: 'USA'
	},
    {
		id: 4,
		image: '/images/trainers/jeff-pestana.png',
		name: 'Jeff Pestana',
		description: 'MxClinic',
		url: 'https://www.instagram.com/mxclinic',
        location: 'CA'
	}
];

export { featuredTrainers };
