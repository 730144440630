import React, { useEffect } from "react";
import { useTheme } from "next-themes";
import { Hero, Bids, Top_collection, Tranding_category, NewsLetter } from "../../components/component";
import HeroRiderSearch from "../../components/hero/RiderSearch";
import Meta from "../../components/Meta";
import UpcomingRaces from '../../components/upcoming-races/upcoming-races';
import OurPartners from '../../components/partners/OurPartners';
import HomePageNewsFeed from '../../components/home-page-news-feed';
import FeaturedTrainers from '../../components/FeaturedTrainers';
import AdvertisingSpotAvailableCard from '../../components/AdvertisingSpotAvailableCard';
import FeaturedTrainingFacilities from '../../components/FeaturedTrainingFacilities';
import OurFeatures from '../../components/hero/OurFeatures';
import MailChimpPopup from '../../components/newsletter/mail-chimp-popup';
import BannerAd from '../../components/banner-ad';
const Home_1 = ({
  recentRaces,
  upcomingRaces,
  featuredFacilities
}) => {
  const {
    theme,
    setTheme
  } = useTheme();
  useEffect(() => {
    setTheme("light");
  }, [setTheme]);
  return <main data-sentry-component="Home_1" data-sentry-source-file="home_1.jsx">
      <Meta title="Welcome to AmateurMoto.com | Your headquarters for everything Amateur Motocross." data-sentry-element="Meta" data-sentry-source-file="home_1.jsx" />
      <MailChimpPopup data-sentry-element="MailChimpPopup" data-sentry-source-file="home_1.jsx" />
      <Hero data-sentry-element="Hero" data-sentry-source-file="home_1.jsx" />
      <OurFeatures data-sentry-element="OurFeatures" data-sentry-source-file="home_1.jsx" />
      <BannerAd data-sentry-element="BannerAd" data-sentry-source-file="home_1.jsx" />
      <AdvertisingSpotAvailableCard link="https://buy.stripe.com/fZe6sbbtJcZ0aDS3ci" data-sentry-element="AdvertisingSpotAvailableCard" data-sentry-source-file="home_1.jsx" />
      <HeroRiderSearch data-sentry-element="HeroRiderSearch" data-sentry-source-file="home_1.jsx" />
      <FeaturedTrainers data-sentry-element="FeaturedTrainers" data-sentry-source-file="home_1.jsx" />
      <FeaturedTrainingFacilities featuredFacilities={featuredFacilities} data-sentry-element="FeaturedTrainingFacilities" data-sentry-source-file="home_1.jsx" />
      <Bids data-sentry-element="Bids" data-sentry-source-file="home_1.jsx" />
      <NewsLetter data-sentry-element="NewsLetter" data-sentry-source-file="home_1.jsx" />
      <UpcomingRaces races={upcomingRaces} data-sentry-element="UpcomingRaces" data-sentry-source-file="home_1.jsx" />
      <Top_collection races={recentRaces} data-sentry-element="Top_collection" data-sentry-source-file="home_1.jsx" />
      <OurPartners data-sentry-element="OurPartners" data-sentry-source-file="home_1.jsx" />
      <HomePageNewsFeed data-sentry-element="HomePageNewsFeed" data-sentry-source-file="home_1.jsx" />
      <Tranding_category data-sentry-element="Tranding_category" data-sentry-source-file="home_1.jsx" />
    </main>;
};
export default Home_1;