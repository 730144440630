import { useRouter } from "next/router";
import Link from 'next/link';
const AdvertisingSpotAvailableCard = ({
  link
}) => {
  return <Link href={link} prefetch={false} data-sentry-element="Link" data-sentry-component="AdvertisingSpotAvailableCard" data-sentry-source-file="AdvertisingSpotAvailableCard.jsx">
      <div className="my-6">
        <section className={`testimonial-section bg-white py-6 dark:bg-jacarta-900`}>
          <div className="container relative overflow-hidden xl:left-[calc((100vw-1202px)/4)] xl:max-w-[calc(1202px+((100vw-1202px)/2))] xl:pr-[calc((100vw-1176px)/2)]">
            <div className="mx-auto mb-6 max-w-sm text-center">
              <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
                Advertising Spot Available
              </h2>
              <p className="text-lg dark:text-jacarta-300 text-accent">
                Click here to purchase this spot.
              </p>
            </div>
          </div>
        </section>
      </div>
    </Link>;
};
export default AdvertisingSpotAvailableCard;